/* global dataLayer */

import EventBase from './base.js'

export default class GoogleAnalyticsEvent extends EventBase {
  buildEvent(event) {
    return event.join('.')
  }

  send() {
    typeof dataLayer !== 'undefined' && dataLayer.push({ event: this.event, ...this.data })
  }
}
