Date.prototype.stdTimezoneOffset = function () {
  const monthsTestOrder = [6, 7, 5, 8, 4, 9, 3, 10, 2, 11, 1, 0]
  const fy = this.getFullYear()

  // eslint-disable-next-line no-prototype-builtins
  if (!Date.prototype.stdTimezoneOffset.cache.hasOwnProperty(fy)) {
    let maxOffset = new Date(fy, 0, 1).getTimezoneOffset()

    for (let mi = 0; mi < 12; mi++) {
      const offset = new Date(fy, monthsTestOrder[mi], 1).getTimezoneOffset()

      if (offset < maxOffset) {
        maxOffset = Math.max(maxOffset, offset)
        break
      } else if (offset > maxOffset) {
        maxOffset = offset
        break
      }
    }

    Date.prototype.stdTimezoneOffset.cache[fy] = maxOffset
  }

  return Date.prototype.stdTimezoneOffset.cache[fy]
}

Date.prototype.stdTimezoneOffset.cache = {}

Date.prototype.isDST = function () {
  return this.getTimezoneOffset() < this.stdTimezoneOffset()
}
