/* global fbq */

import EventBase from './base.js'

export default class FacebookEvent extends EventBase {
  buildEvent(event) {
    return event.join('.')
  }

  send() {
    this.environment === 'production' &&
      typeof fbq !== 'undefined' &&
      fbq('track', this.event, { ...this.data })
  }
}
