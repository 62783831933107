/* global HarleyEventStore */

import { captureException } from '@sentry/react'

import EventBase from './base.js'

export default class HarleyTherapyEventStore extends EventBase {
  static supportsBatching = true

  buildEvent(event) {
    return typeof event === 'object' ? event.join('.') : event
  }

  // If an array is given as the data, it is assumed that each element in the array is a data object
  // for a single event, and will be sent to the server in a single request. The server will then
  // turn that into multiple events per array element.
  send() {
    // If batching, send an object with two keys: `data` and `collectors`.
    const data = Array.isArray(this.data) ? { data: Array.from(this.data) } : this.data
    data.collectors = this.collectors

    try {
      typeof HarleyEventStore !== 'undefined' && HarleyEventStore.track(this.event, data)
    } catch (error) {
      captureException(error)
    }
  }
}
