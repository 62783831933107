import { addDays, isSameDay } from 'date-fns'

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

function pad(num) {
  return `0${num}`.slice(-2)
}

export function strftime(time, formatString) {
  const day = time.getDay()
  const date = time.getDate()
  const month = time.getMonth()
  const year = time.getFullYear()
  const hour = time.getHours()
  const minute = time.getMinutes()
  const second = time.getSeconds()
  return formatString.replaceAll(/%([%ABHIMPSWYZa-elmpwyz])/g, function (_arg) {
    let match
    const modifier = _arg[1]
    switch (modifier) {
      case '%': {
        return '%'
      }
      case 'a': {
        return weekdays[day].slice(0, 3)
      }
      case 'A': {
        return weekdays[day]
      }
      case 'b': {
        return months[month].slice(0, 3)
      }
      case 'B': {
        return months[month]
      }
      case 'c': {
        return time.toString()
      }
      case 'd': {
        return pad(date)
      }
      case 'e': {
        return date
      }
      case 'H': {
        return pad(hour)
      }
      case 'I': {
        return pad(strftime(time, '%l'))
      }
      case 'l': {
        return hour === 0 || hour === 12 ? 12 : (hour + 12) % 12
      }
      case 'm': {
        return pad(month + 1)
      }
      case 'M': {
        return pad(minute)
      }
      case 'p': {
        return hour > 11 ? 'PM' : 'AM'
      }
      case 'P': {
        return hour > 11 ? 'pm' : 'am'
      }
      case 'S': {
        return pad(second)
      }
      case 'w': {
        return day
      }
      case 'W': {
        if (isSameDay(new Date(), time)) return 'Today'
        if (isSameDay(time, addDays(new Date(), 1))) return 'Tomorrow'
        if (time <= addDays(new Date(), 6)) return strftime(time, '%A')

        return strftime(time, '%a, %b %e')
      }
      case 'y': {
        return pad(year % 100)
      }
      case 'Y': {
        return year
      }
      case 'Z': {
        match = time.toString().match(/\((\w+)\)$/)
        return match ? match[1] : ''
      }
      case 'z': {
        match = time.toString().match(/\w([+-]\d{4}) /)
        return match ? match[1] : ''
      }
    }
  })
}

export function makeFormatter(options) {
  if (typeof Intl !== 'undefined') {
    try {
      return new Intl.DateTimeFormat(undefined, options)
    } catch (error) {
      if (!(error instanceof RangeError)) {
        throw error
      }
    }
  }
}

let dayFirst = null

// Private: Determine if the day should be formatted before the month name in
// the user's current locale. For example, `9 Jun` for en-GB and `Jun 9`
// for en-US.
//
// Returns true if the day appears before the month.
export function isDayFirst() {
  if (dayFirst !== null) {
    return dayFirst
  }

  const formatter = makeFormatter({ day: 'numeric', month: 'short' })
  if (formatter) {
    const output = formatter.format(new Date(0))
    dayFirst = !!/^\d/.test(output)
    return dayFirst
  } else {
    return false
  }
}

let yearSeparator = null

// Private: Determine if the year should be separated from the month and day
// with a comma. For example, `9 Jun 2014` in en-GB and `Jun 9, 2014` in en-US.
//
// Returns true if the date needs a separator.
export function isYearSeparator() {
  if (yearSeparator !== null) {
    return yearSeparator
  }

  const formatter = makeFormatter({ day: 'numeric', month: 'short', year: 'numeric' })
  if (formatter) {
    const output = formatter.format(new Date(0))
    yearSeparator = !!/\d,/.test(output)
    return yearSeparator
  } else {
    return true
  }
}

// Private: Determine if the date occurs in the same year as today's date.
//
// date - The Date to test.
//
// Returns true if it's this year.
export function isThisYear(date) {
  const now = new Date()
  return now.getUTCFullYear() === date.getUTCFullYear()
}
