import RelativeTime from './relative_time.js'
import RelativeTimeElement from './relative_time_element.js'

export default class TimeAgoElement extends RelativeTimeElement {
  getFormattedDate() {
    if (this._date) {
      const format = this.getAttribute('format')
      return format === 'micro'
        ? new RelativeTime(this._date).microTimeAgo()
        : new RelativeTime(this._date).timeAgo()
    }
  }
}

if (!window.customElements.get('time-ago')) {
  window.TimeAgoElement = TimeAgoElement
  window.customElements.define('time-ago', TimeAgoElement)
}
