import FacebookEvent from './collectors/facebook.js'
import FacebookCustomEvent from './collectors/facebook_custom.js'
import GoogleAnalyticsEvent from './collectors/google_analytics.js'
import HarleyTherapyEventStore from './collectors/harley.js'
import IntercomEvent from './collectors/intercom.js'

export default class HarleyEvent {
  allCollectors = {
    GoogleAnalytics: GoogleAnalyticsEvent,
    Intercom: IntercomEvent,
    Facebook: FacebookEvent,
    FacebookCustom: FacebookCustomEvent,
    Harley: HarleyTherapyEventStore
  }

  static initialize() {
    if (typeof document === 'undefined') return

    // Track links that need event reporting.
    const elements = document.querySelectorAll('[data-harley-event]')
    for (let i = elements.length - 1; i >= 0; i--) {
      elements[i].addEventListener('click', function () {
        new HarleyEvent(JSON.parse(this.dataset.harleyEvent))
      })
    }

    document.dispatchEvent(new CustomEvent('HarleyEventReady'))
  }

  constructor(params) {
    const collectors = this.collectors(params.collectors)

    params.data = params.data === undefined ? {} : params.data

    const env = document.getElementsByName('htp:environment')[0].content

    // Ensure `event` is an array.
    params.event = Array.isArray(params.event) ? params.event : [params.event]

    // Prefix the event with admin if path starts with `/admin`.`
    if (window.location.hostname.startsWith('admin-')) {
      params.event.splice(0, 0, 'admin-')
    } else if (window.location.hostname.startsWith('admin')) {
      params.event.splice(0, 0, 'admin')
    }

    // pick up subdomains if there is one and prefix the event with this
    const domainParts = window.location.hostname.split('.')
    if (domainParts.length > 2) {
      params.data.subdomain = domainParts[0]
    }

    const { event, data } = params
    collectors.forEach(c => {
      // Not all collectors support batching, so we check here.
      if (Array.isArray(data) && !this.allCollectors[c].supportsBatching) {
        data.forEach(d => {
          new this.allCollectors[c](env, { data: d, event, collectors })
        })
      } else {
        new this.allCollectors[c](env, { event, data, collectors })
      }
    })
  }

  collectors(value) {
    return value.length === 0 ? ['Harley'] : Array.from(new Set(value.concat(['Harley'])))
  }
}

if (typeof window !== 'undefined' && window.HarleyEvent === undefined) {
  window.HarleyEvent = HarleyEvent
}
