import RelativeTime from './relative_time.js'
import RelativeTimeElement from './relative_time_element.js'

export default class TimeUntilElement extends RelativeTimeElement {
  getFormattedDate() {
    if (this._date) {
      return this.getAttribute('format') === 'micro'
        ? new RelativeTime(this._date).microTimeUntil()
        : new RelativeTime(this._date).timeUntil()
    }
  }
}

if (!window.customElements.get('time-until')) {
  window.TimeUntilElement = TimeUntilElement
  window.customElements.define('time-until', TimeUntilElement)
}
