/* global smartlook */

import 'assets/lib/react/client.jsx'
import 'assets/lib/time.js'
import 'components/time_elements/index.js'
import Rails from '@rails/ujs'

document.addEventListener('DOMContentLoaded', () => {
  Rails.start()

  // Handle post sign out
  if (location.search !== '') {
    const params = new URLSearchParams(location.search)

    if (params.has('signed_out') && params.get('signed_out') === '1') {
      typeof smartlook !== 'undefined' && smartlook('anonymize')
    }
  }
})
