export default class EventBase {
  static supportsBatching = false

  constructor(environment, params) {
    this.environment = environment
    this.collectors = params.collectors
    this.event = this.buildEvent(params.event)
    this.data = params.data

    this.log()
    this.send()
  }

  buildEvent(event) {
    return event.join('-')
  }

  log() {
    if (this.environment != 'production') {
      let title = 'HarleyEvent'
      if (Array.isArray(this.data)) {
        title += ' (batched)'
      }

      // eslint-disable-next-line no-console
      console.debug(title, this.constructor.name, {
        event: this.event,
        data: this.data
      })
    }
  }
}
